<div class="container-fluid mt-0 container-enterprise">

    <div class="row">
        <div class="col-12 col-md-4">
            <div class="card">
                <div class="card-header">
                    <h4 class="mb-0">Generales</h4>
                </div>
                <div class="card-body">
                    <div>
                        <div class="row d-flex justify-content-center mb-2 position-relative">
                            <i class="fa fa-pencil text-info" id="editPencil" (click)="imgFile.click()"></i>
                            <input type="file" #imgFile style="display: none;" accept="image/jpg"
                                (change)="uploadImageFile($event)">
                            <img *ngIf="!collaborador?.imageFileUrl" width="100px"
                                src="../../../../../assets/images/user.png" alt="User Image">
                            <img *ngIf="collaborador?.imageFileUrl" width="100px" [src]="collaborador?.imageFileUrl"
                                alt="User Image">

                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Nombre(s)</span><br>
                                <b>{{ collaborador?.firstName }}</b>
                            </div>
                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Apellido(s)</span><br>
                                <b>{{ collaborador?.lastName }}</b>
                            </div>
                        </div>

                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Puesto</span><br>
                                <b>{{ collaborador?.position }}</b>
                            </div>
                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Género</span><br>
                                <b>{{ collaborador?.gender }}</b>
                            </div>
                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Fecha de nacimiento</span><br>
                                <b>{{ (collaborador?.dateOfBirth | date: 'dd/MM/YYYY') || '-' }}</b>
                            </div>
                        </div>
                        <div class="row d-flex flex-row align-items-center col-xl-12 col-md-4 mb-3">
                            <div class="d-flex flex-row">
                                <i class="fa fa-briefcase"></i>
                            </div>
                            <div class=" px-1">
                                <span>Número telefónico</span><br>
                                <b>{{ collaborador?.phone || '-' }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-8">
            <div class="card custom-card-margin">
                <div class="d-flex justify-content-between p-3 w-100">
                    <div>
                        <h4 class="">Historial clinico</h4>
                    </div>
                    <div>
                        <a class="btn btn-sm btn-red" *ngIf="userRoles === 'admin'" [routerLink]="['new-history']">Nueva
                            historia</a>
                    </div>
                </div>
            </div>

            <div class="card custom-card-margin">
                <div class="card-body tabla-card-margin">
                    <div id="batchDelete" class="body overflow-auto">
                        <div class="table-responsive">
                            <table mat-table #table [dataSource]="dataSource" matSort
                                class="table table-responsive w-100 table-hover row-border hover">

                                <ng-container matColumnDef="date">
                                    <th *matHeaderCellDef mat-sort-header>Fecha de elaboración</th>
                                    <td *matCellDef="let row">{{ row?.created_at | date:'dd/MM/YYYY hh:mm a' }}</td>
                                </ng-container>
                                <ng-container class="custom-font-size-th" matColumnDef="reason">
                                    <th *matHeaderCellDef mat-sort-header>Tipo de éxamen</th>
                                    <td *matCellDef="let row">{{row?.reason}}</td>
                                </ng-container>
                                <ng-container class="custom-font-size-th" matColumnDef="actions">
                                    <th *matHeaderCellDef mat-sort-header>Acciones</th>
                                    <td *matCellDef="let row" style="font-size: 16px;">
                                        <span>
                                            <a [routerLink]="['details', row.id]" title="Ver historia clinica">
                                                <i class="fa fa-eye"></i>
                                            </a>
                                        </span>
                                        <!-- <span>
                                            <a [routerLink]="['/colaboradores/history-clinic', row.id]"
                                                (click)="obtenerId(row.id)" title="Ver historial clinico">
                                                <i class="fa fa-clipboard"></i>
                                            </a>
                                        </span>
                                        <span title="Eliminar colaborador" (click)="setModalContent(content, row.id)"
                                            *ngIf="row.active">
                                            <i class="fa fa-trash"></i>
                                        </span> -->
                                        <!-- <span>
                                      <a [routerLink]="['/colaboradores/historyClinic']" (click)="obtenerId(row.id)"
                                          title="Agregar Historial Clinico">
                                          <i class="fa fa-plus"></i>
                                      </a>
                                    </span> -->
                                        <!--  <span
                                    title="Eliminar colaborador" (click)="setModalContent(content, row.id)" *ngIf="row.active">
                                    <i class="fa fa-trash"></i>
                                    </span>
                                    <span
                                    title="Activar colaborador" (click)="setModalContent(contentactive, row.id)" *ngIf="!row.active">
                                    <i class="fa fa-check"></i>
                                    </span> -->
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                <!-- <thead> 
                                <tr>
                                <th *ngIf="this.userRoles == 'admin'" class="custom-font-size-th custom-width-th-empresa">Nombre de empresa</th>
                                <th class="custom-font-size-th">Nombre(s)</th>
                                <th class="custom-font-size-th">Apellido(s)</th>
                                <th class="custom-font-size-th custom-width-th-empresa">Fecha de nacimiento</th>
                                <th class="custom-font-size-th">Puesto</th>
                                <th class="custom-font-size-th">Correo electrónico</th>
                                <th class="custom-font-size-th">Teléfono</th>
                                <th class="custom-font-size-th">Status</th>
                                <th class="custom-font-size-th custom-width-th-acciones">Acciones</th>
                                </tr>
                            </thead>
                              <tbody *ngFor="let collaborator of collaboratorsList">
                                <tr>
                                <td *ngIf="this.userRoles == 'admin'"  class="custom-font-size-td custom-width-td">{{collaborator.Business?.businessname}}</td>
                                <td class="custom-font-size-td custom-width-td">{{collaborator.firstName}}</td>
                                <td class="custom-font-size-td custom-width-td">{{collaborator.lastName}}</td>
                                <td class="custom-font-size-td custom-width-td">{{collaborator.birthday}}</td>
                                <td class="custom-font-size-td custom-width-td">{{collaborator.Job}}</td>
                                <td class="custom-font-size-td custom-width-td">{{collaborator.email}}</td>
                                <td class="custom-font-size-td custom-width-td">{{collaborator.phoneNumber}}</td>
                                <td class="custom-font-size-td custom-width-td">{{collaborator.active ? 'Activo': 'Inactivo'}}</td>
                                <td class="actions-cell custom-width-td-action">
                                    <span>
                                    <a 
                                    (click)="sendPhone(collaborator.phoneNumber)"
                                    [routerLink]="['/colaboradores/detalle', collaborator.id]"
                                        title="Ver colaborador">
                                        <i class="fa fa-eye"></i>
                                    </a>
                                    </span>
                                    <span>
                                      <a [routerLink]="['/colaboradores/editar', collaborator.id]"
                                      (click)="sendPhone(collaborator.phoneNumber)"
                                          title="Editar colaborador">
                                          <i class="fa fa-edit"></i>
                                      </a>
                                    </span>
                                    <span>
                                      <a [routerLink]="['/colaboradores/detail-historyClinic', collaborator.id]" (click)="obtenerId(collaborator.id)"
                                          title="Ver historial clinico">
                                          <i class="fa fa-clipboard"></i>
                                      </a>
                                    </span>
                                  <span>
                                      <a [routerLink]="['/colaboradores/historyClinic']" (click)="obtenerId(collaborator.id)"
                                          title="Agregar Historial Clinico">
                                          <i class="fa fa-plus"></i>
                                      </a>
                                    </span>
                                    <span
                                    title="Activar colaborador" (click)="setModalContent(contentactive, collaborator.id)" *ngIf="!collaborator.active">
                                    <i class="fa fa-check"></i>
                                    </span>
                                </td>
                                </tr>
                            </tbody> -->
                            </table>
                            <h3 *ngIf="collaboratorTotal === 0">
                                Este colaborador no cuenta con historial clinico
                            </h3>
                            <ngb-pagination (pageChange)="loadPage($event)" [(collectionSize)]="collaboratorTotal"
                                [(page)]="page" [pageSize]="limit" [rotate]="true"
                                [boundaryLinks]="true"></ngb-pagination>
                            <div class="d-flex justify-content-center w-100">
                                <ngb-toast *ngIf="submittedError" [class]="'ngb-toast bg-error'">
                                    {{messageError}}
                                </ngb-toast>
                                <ngb-toast *ngIf="submittedSuccess" [class]="'ngb-toast bg-success'">
                                    {{messageSuccess}}
                                </ngb-toast>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title">Confirmar baja</h4>
    </div>
    <div class="modal-body text-center">
        <ng-container *ngIf="!deleteIsSuccess">
            <p class="no-mrg">¿Realmente deseas dar de baja a este colaborador?</p>
            <p class="no-mrg">Esta acción no es posible revertir.</p>
        </ng-container>

        <ng-container *ngIf="deleteIsSuccess">
            <p>Empresa borrada correctamente</p>
        </ng-container>
    </div>
    <ng-container *ngIf="!deleteIsSuccess">
        <div class="modal-footer">
            <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
            <button type="button" class="btn btn-inverse btn-danger" (click)="deleteUser()">Sí, Confirmar</button>
        </div>
    </ng-container>
</ng-template>

<ng-template #contentactive let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title">Activar colaborador</h4>
    </div>
    <div class="modal-body text-center">
        <ng-container *ngIf="!deleteIsSuccess">
            <p class="no-mrg">¿Confirma la activación del colaborador {{deleteCollaborators}}?</p>
            <p class="no-mrg">Al dar de activar al colaborador, podrá
                acceder al sistema y cargar información de
                colaboradores.</p>
        </ng-container>

        <ng-container *ngIf="deleteIsSuccess">
            <p>Colaborador activado correctamente</p>
        </ng-container>
    </div>
    <ng-container *ngIf="!deleteIsSuccess">
        <div class="modal-footer">
            <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
            <button type="button" class="btn btn-inverse btn-success" (click)="activeUser()">Sí, Confirmar</button>
        </div>
    </ng-container>
</ng-template>