<div class="container-fluid mt-0 container-enterprise">
  <div class="d-flex justify-content-between px-3 pb-3">
    <h4 class="px-2">Empresas</h4>
    <h4>Total de empresas : {{count}}</h4>
  </div>
  <div class="card custom-card-margin">
    <div class="card-body d-flex custom-flex-enterprise-list justify-content-between align-items-end w-100">
      <div class="row d-flex flex-column col-lg-10 col-md-10 col-sm-12 pr-0 mx-0 custom-margin-enterprise-input-mobile" style="padding: 0px">
        <!--  <label>Filtrar</label> -->
        <div class="input-group" style="width: 100%">
          <input type="text" id="filterText" [(ngModel)]="companyFilter"
            placeholder="Buscar por nombre de empresa, representante o correo electrónico"
            class="input-font-size form-control">
          <div class="input-group-append">
            <button *ngIf="!deleteFilters" value="Buscar" (click)="filter(companyFilter)"
              class="button-font-size btn btn-primary">Buscar</button>
            <button *ngIf="deleteFilters" value="Borrar" (click)="getEnterprise()"
              class="button-font-size btn btn-primary">Borrar filtros</button>
          </div>
        </div>
      </div>
      <div class="row action pr-0 mx-0 col-lg-2 col-md-2 col-sm-12 custom-margin-enterprise-mobile">
        <a class="custom-button-width button-font-size btn btn-red btn-sm px-2" [routerLink]="['/empresa/agregar']" style="width: 100%">
          <i class="ti-plus"></i>
          <span><i class="fa fa-plus"></i> Nueva Empresa</span>
        </a>
      </div>
    </div>
  </div>

  <div class="card custom-card-margin">
    <div class="card-body tabla-card-margin">
      <div id="batchDelete" class="body overflow-auto">
        <div class="table-responsive">
          <table mat-table class="w-100" (matSortChange)="announceSortChange($event)" #table [dataSource]="dataSource"
            matSort class="table table-responsive table-lg table-hover row-border hover">
            <ng-container matColumnDef="businessname">
              <th *matHeaderCellDef mat-sort-header>Nombre de empresa</th>
              <td *matCellDef="let row">{{ row.businessname}}</td>
            </ng-container>
            <ng-container matColumnDef="displayName">
              <th *matHeaderCellDef mat-sort-header>Representante</th>
              <td *matCellDef="let row">{{ row.displayName}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th *matHeaderCellDef mat-sort-header>Correo electrónico</th>
              <td *matCellDef="let row">{{ row.email}}</td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
              <th *matHeaderCellDef mat-sort-header>Numero telefonico</th>
              <td *matCellDef="let row">{{ row.phoneNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="collaborators">
              <th *matHeaderCellDef mat-sort-header>Colaboradores</th>
              <td *matCellDef="let row">{{ row.collaborators}}</td>
            </ng-container>
            <ng-container matColumnDef="active">
              <th *matHeaderCellDef mat-sort-header>Estatus</th>
              <td *matCellDef="let row">
                <button class="btn status-btn" [ngClass]="{'btn-success': row.active, 'btn-warning': !row.active}">
                  {{ row.active ? 'Activo' : 'Inactivo' }}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th *matHeaderCellDef mat-sort-header>Acciones</th>
              <td *matCellDef="let row" style="font-size: 16px;">
                <span>
                  <a [routerLink]="['/empresa/detalle', row.id]" title="Ver empresa">
                    <i class="fa fa-eye"></i>
                  </a>
                </span>
                <span>
                  <a [routerLink]="['/empresa/editar', row.id]" title="Editar empresa">
                    <i class="fa fa-edit"></i>
                  </a>
                </span>
                <span title="Eliminar empresa" (click)="setModalContent(content, row.id)" *ngIf="row.active">
                  <i class="fa fa-trash"></i>
                </span>
                <span title="Activar empresa" (click)="setModalContent(contentactive, row.id)" *ngIf="!row.active">
                  <i class="fa fa-check"></i>
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <ngb-pagination (pageChange)="loadPage($event)" [(collectionSize)]="count" [(page)]="page" [pageSize]="limit"
            [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
          <div class="d-flex justify-content-center w-100">
            <ngb-toast *ngIf="submittedError" [class]="'ngb-toast bg-error'">
              {{messageError}}
            </ngb-toast>
            <ngb-toast *ngIf="submittedSuccess" [class]="'ngb-toast bg-success'">
              {{messageSuccess}}
            </ngb-toast>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100" id="modal-title">Confirma baja de empresa</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">¿Confirma la baja de la empresa {{deleteEnterprise}}?</p>
      <p class="no-mrg">Al dar de baja la empresa, no podrá
        acceder al sistema y cargar información de
        colaboradores.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Empresa borrada correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
      <button type="button" class="btn btn-inverse btn-danger" (click)="deleteUser()">Sí, Confirmar</button>
    </div>
  </ng-container>
</ng-template>

<ng-template #contentactive let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100" id="modal-title">Activar empresa</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">¿Confirma la activación de la empresa {{deleteEnterprise}}?</p>
      <p class="no-mrg">Al dar de activar la empresa, podrá
        acceder al sistema y cargar información de
        colaboradores.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Empresa activada correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
      <button type="button" class="btn btn-inverse btn-success" (click)="activeUser()">Sí, Confirmar</button>
    </div>
  </ng-container>
</ng-template>
<!-- <div class="flex flex-col flex-auto min-w-0">
    
    <div class="flex items-center justify-between p-6" >
      <h1 class="text-2xl font-bold uppercase">MI ORGANIZACIÓN</h1>
    </div>
    <div class="flex-auto px-6 sm:px-10" >
      <div class="w-full bg-card rounded overflow-hidden">
        <form class="flex flex-col pt-8">
          <div class="flex flex-wrap  px-8">
            <div class="flex flex-wrap w-full mb-8 lg:mb-3 md:mb-3">
             <mat-form-field class="w-full flex-auto" appearance="outline">
                <label>Nombre comercial</label>
                <input matInput disabled [placeholder]="'Nombre usuario'" [formControlName]="'BusinessName'">
                 <mat-icon class="icon-size-5 mx-4" matPrefix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> 
             </mat-form-field> 
            </div>
            <div class="flex flex-wrap w-full mb-8 lg:mb-3 md:mb-3">
              <mat-form-field class="w-full flex-auto" appearance="outline"> 
                <label>RFC</label>
                <input matInput disabled [placeholder]="'RFC'" [formControlName]="'TaxId'">
                <mat-icon class="icon-size-5 mx-4" matPrefix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> 
             </mat-form-field> 
            </div>
            <div class="flex flex-wrap w-full mb-8 lg:mb-3 md:mb-3">
             <mat-form-field class="w-full flex-auto" appearance="outline">
                <label>Régimen</label>
                 <mat-select [placeholder]="'Régimen'" [formControlName]="'TaxRegime'">
                    <mat-option value="Física">Física</mat-option>
                    <mat-option value="Moral">Moral</mat-option>
                </mat-select> 
                 <mat-icon class="icon-size-5 mx-4" matPrefix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> 
               </mat-form-field> 
            </div>
            <div class="flex flex-wrap w-full mb-8 lg:mb-3 md:mb-3">
               <mat-form-field class="w-full flex-auto" appearance="outline"> 
                <label>Tipo de local</label>
                <input matInput disabled [placeholder]="'Tipo de local'" [formControlName]="'LocalType'">
                 <mat-icon class="icon-size-5 mx-4" matPrefix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> 
               </mat-form-field> 
            </div>
            <div class="flex flex-wrap w-full mb-8 lg:mb-3 md:mb-3">
               <mat-form-field class="w-full flex-auto" appearance="outline"> 
                <label>Correo electrónico</label>
                <input matInput disabled [placeholder]="'Correo electrónico'" [formControlName]="'ContactEmail'">
                 <mat-icon class="icon-size-5 mx-4" matPrefix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> 
               </mat-form-field> 
            </div>
            <div class="flex flex-wrap w-full mb-8 lg:mb-3 md:mb-3">
               <mat-form-field class="w-full flex-auto" appearance="outline"> 
                <label>Teléfono</label>
                <input matInput disabled [placeholder]="'Teléfono'" [formControlName]="'MobilePhone'">
                 <mat-icon class="icon-size-5 mx-4" matPrefix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> 
               </mat-form-field> 
            </div>
            <div class="flex flex-wrap w-full mb-8 lg:mb-3 md:mb-3">
               <mat-form-field class="w-full flex-auto" appearance="outline"> 
                <label>Teléfono de oficina</label>
                <input matInput disabled [placeholder]="'Teléfono de oficina'" [formControlName]="'OfficePhone'">
                 <mat-icon class="icon-size-5 mx-4" matPrefix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> 
               </mat-form-field> 
            </div>
            <div class="flex items-center justify-end  mb-8">
              <button  class="c-button py-2 px-2" type="button">
                Editar datos
              </button>
              <button class="c-button py-2 px-2" type="submit">
                Guardar
              </button>
               <a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                Forgot Password?
              </a> 
            </div>
          </div>
        </form>
      </div>
    </div>
  
    <div class="main-table">
      
      <div class="cabezera-table">
        <div class="flex flex-wrap w-full items-center justify-between px-8">
          <div class="w-full sm:w-2/3">
              <h1 class="text-2xl font-bold text-uppercase">USUARIOS</h1>
            </div>
            <div class="w-full sm:w-1/3 text-right">
               <button class="mt-2 w-full" mat-flat-button [color]="'primary'" [routerLink]="['/company/add-user']">
                <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                Agregar usuario
              </button> 
              <div class="flex flex-wrap justify-center">
                <div class="dropdown relative w-full">
                  <button
                    class="
                      c-button
                      dropdown-toggle
                      w-full
                      px-6
                      mt-2
                      py-2.5
                      text-white
                      transition
                      duration-150
                      ease-in-out
                      flex
                      items-center
                      justify-center
                      whitespace-nowrap
                    "
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                   <mat-icon class="icon-size-5 mr-3 text-white" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon> 
                    Agregar usuario
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="caret-down"
                      class="w-2 ml-2"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                      ></path>
                    </svg>
                  </button>
                  <ul
                    class="
                      dropdown-menu
                      w-full
                      absolute
                      hidden
                      bg-white
                      z-50
                      float-left
                      py-2
                      list-none
                      text-center
                      rounded-lg
                      mt-1
                      hidden
                      m-0
                      bg-clip-padding
                      border-none
                    "
                    id="dropdownMenuButton2"
                  >
                    <li>
                      <a
                        class="
                          dropdown-item
                          text-sm
                          py-2
                          px-4
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                          text-gray-700
                          hover:bg-gray-100
                          cursor-pointer
                        "
                       
                        >Desde archivo</a
                      >
                    </li>
                    <li>
                      <a
                        class="
                          dropdown-item
                          text-sm
                          py-2
                          px-4
                          block
                          w-full
                          whitespace-nowrap
                          bg-transparent
                          text-gray-700
                          hover:bg-gray-100
                        "
                        [routerLink]="['/company/add-user']"
                        >Manualmente</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
  
      </div>
  
  
        
         <div class="h-400 min-h-400 max-h-400 border-2 border-dashed border-gray-300 rounded-2xl"></div> 
      <div class="bg-card content-table">
  
        <div class="flex flex-wrap px-6 py-5">
          <div class="w-full">
            <h3 class="px-2 pb-3 text-l">Filtrar:</h3>
            <form>
              <div class="flex w-full items-center position-relative">
                <div class="flex">
                  <div class="w-full flex">
                    <div class="w-full flex">
                      <input class="w-full p-2 mx-2 border-2 border-double border-contisign" placeholder="input.placeholder" type="text">
                      <input   class="w-full p-2 mx-2 border-2 border-double border-contisign" placeholder="input.placeholder " type="text" onfocus="(this.type='date')">
                      <div class="w-full text-center">
                         <label>{{ filter.name }}</label> 
                         <mat-slide-toggle class="ml-3"></mat-slide-toggle> 
                      </div>
                        <select class="w-full p-2 mx-2 border-2 border-double border-contisign">
                           <option *ngFor="let data of input.data" [value]="data.id">{{data.Name}}</option> 
                            <option>data.Name</option>
                        </select>
                    </div>
                  </div>
                </div>
                 <input class="w-1/2 p-2 mx-2 border-2 border-double border-contisign" placeholder="Documentos, usuarios" type="text"> 
                <button class="flex-shrink-0 text-sm c-button py-2 px-2 mr-2" type="submit">
                  Buscar
                </button>
                <button class="flex-shrink-0 text-sm c-button py-2 px-2" id="menu-button" aria-expanded="true" aria-haspopup="true">
                  Filtros
                </button>
    
                <div id="dropdown" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                  <div class="py-1" role="none">
                    <a class="text-gray-700 block px-4 py-2 cursor-pointer" role="menuitem" tabindex="-1"  id="menu-item-0">
                       <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:badge-check'"></mat-icon>   
                      <span [ngClass]="'ml-5' ">
                         {{ item.name }} 
                      </span>
                    </a>
                  </div>
                </div>
    
              </div>
            </form>
          </div>
        </div>
  
         <mat-divider class="bg-white mb-4"></mat-divider> 
  
        <div class="px-8 pb-12">
          <table class="p-4" >
            <tbody>
              <tr class="fila">
                <td [attr.data-column]="'Nombre'" >
                  <span
                    class="cursor-pointer"
                   
                  >
                     {{ plan.firstName+' '+plan.lastName }} 
                     <mat-icon
                      *ngIf="!showed && showed !== i"
                      id="moreIcon"
                      class="icon-size-5 cursor-pointer"
                      [svgIcon]="'heroicons_solid:chevron-down'"
                    ></mat-icon> 
                     <mat-icon
                      *ngIf="showed === i"
                      id="moreIcon"
                      class="icon-size-5 cursor-pointer"
                      [svgIcon]="'heroicons_solid:chevron-up'"
                    ></mat-icon> 
                  </span>
                  </td>
                  <td [attr.data-column]="'Correo electrónico'" >
                    <span
                      class="cursor-pointer"
                    >
                       {{ plan.email }} 
                    </span>
                    </td>
                    <td [attr.data-column]="'Departamento'" >
                      <span
                        class="cursor-pointer"
                      >
                         {{ plan.Department }} 
                      </span>
                      </td>
                      <td [attr.data-column]="'Puesto'" >
                        <span
                          class="cursor-pointer"
                        >
                           {{ plan.Position }} 
                        </span>
                        </td>
                  <td [attr.data-column]="'Estado'">
                  <span>
                    <span
                      class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                    >
                       <span class="leading-relaxed whitespace-nowrap">{{
                        plan.Active === true ? "Activo" : ""
                      }}</span>
                      <span class="leading-relaxed whitespace-nowrap">{{
                        plan.Active === false ? "Inactivo" : ""
                      }}</span> 
                    </span>
                  </span>
                  </td>
                  <td [attr.data-column]="'Opciones'">
                    <span>
                      <span>
                        <button >
                           <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:document-text'"></mat-icon> 
                        </button>
                        <a >
                           <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:pencil-alt'"></mat-icon> 
                        </a>
                        <button >
                           <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon> 
                        </button>
                      </span>
                    </span>
                </td>
              </tr>
            </tbody>
  
        </table>
    
        <table mat-table class="w-full">
    
             Name Column 
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef> Nombre </th>
              <td mat-cell *matCellDef="let user"> {{user.firstName}} {{user.lastName}} </td>
            </ng-container>
    
             RFC Column 
            <ng-container matColumnDef="Email">
              <th mat-header-cell *matHeaderCellDef> Correo electrónico </th>
              <td mat-cell *matCellDef="let user"> {{user.email}} </td>
            </ng-container>
    
             Department Column 
            <ng-container matColumnDef="Department">
              <th mat-header-cell *matHeaderCellDef> Departamento </th>
              <td mat-cell *matCellDef="let user"> {{user.Department}} </td>
            </ng-container>
    
             RFC Column 
            <ng-container matColumnDef="Position">
              <th mat-header-cell *matHeaderCellDef> Puesto </th>
              <td mat-cell *matCellDef="let user"> {{user.Position}} </td>
            </ng-container>
    
            Email Column 
             <ng-container matColumnDef="Rol">
              <th mat-header-cell *matHeaderCellDef> Rol </th>
              <td mat-cell *matCellDef="let user">
                <span
                  class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                  [ngClass]="{'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': user.roles === 'business',
                                      'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50': user.roles === 'user'}">
                  <span class="leading-relaxed whitespace-nowrap">{{user.roles === 'business' ? 'Responsable' :
                    'Usuario'}}</span>
                </span>
              </td>
            </ng-container> 
    
             Email Column 
            <ng-container matColumnDef="Active">
              <th mat-header-cell *matHeaderCellDef> Estado </th>
              <td mat-cell *matCellDef="let user">
                <span
                  class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                  [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': user.Active === false,
                                      'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': user.Active === true}">
                  <span class="leading-relaxed whitespace-nowrap">{{user.Active === true ? 'Activo' : 'Inactivo'}}</span>
                </span>
              </td>
            </ng-container>
    
             Options Column 
            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef> Opciones </th>
              <td mat-cell *matCellDef="let user">
                <div class="ml-1">
                  <button class="w-8 h-8 min-h-8" mat-icon-button [matMenuTriggerFor]="listMenu">
                    <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
                  </button>
                  <mat-menu #listMenu="matMenu">
                    <button mat-menu-item [routerLink]="['record', user?.id]">
                      <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:document-text'"></mat-icon>
                      Ver expediente electrónico
                    </button>
                    <a mat-menu-item [routerLink]="['edit', user?.id]">
                      <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:pencil-alt'"></mat-icon>
                      Editar usuario
                    </a>
                    <button *ngIf="user.Active" mat-menu-item (click)="openDialog(dialog2, user)">
                      <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:eye-slash'"></mat-icon>
                      Desactivar usuario
                    </button>
                    <button *ngIf="!user.Active" mat-menu-item (click)="openDialog(dialog3, user)">
                      <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                      Activar usuario
                    </button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    
           <mat-paginator
          [length]="count"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
          (page)="pageChanged($event)"
          aria-label="Seleccionar página"
        >
        </mat-paginator> 
  
           <mat-paginator [length]="count" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
            aria-label="Seleccionar página">
          </mat-paginator>
        </div>
  
        <div class="flex flex-wrap px-6 py-5">
          <div class="w-full text-center items-center">
            <img src="../../../../assets/images/no-data.svg" style="width:50% !important; margin: 0 auto !important;" alt="">
            <h1 class="text-2xl pb-5"> No se han encontrado resultados </h1> 
          </div>
      </div>
  
      </div>
  
    </div>
  
  </div>
  
  
  <ng-template #dialog2 class="dialog">
    <div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
        <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-contisign text-on-primary">
            <div class="text-lg font-medium">Desactivar usuario</div>
            <button mat-icon-button (click)="close(dialog)" [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
        <form class="flex flex-col mt-4 px-8 bg-card shadow rounded overflow-hidden">
  
            <div class="grid grid-cols-1 gap-2">
                <div class="flex flex-col gt-xs:flex-row py-6 text-center">
                    <h3 class="" for="grid-last-name">
                      Al desactivar a este usuario, no le permitirá acceder a la plataforma <br>
                        ¿Realmente desea darlo desactivarlo?
                    </h3>
                </div>
            </div>
        </form>
        <fuse-alert class="mt-8 -mb-4 px-8" *ngIf="showPass" [appearance]="'outline'" [showIcon]="false"
            [type]="alert.type">
            {{alert.message}}
        </fuse-alert>
        <div class="py-5 flex flex-row-reverse  mr-5">
  
            <button 
            class="flex-shrink-0 text-sm c-button py-2 px-2"
            (click)="deleteUser()">
                <span>
                    Confirmar
                </span>
                <mat-progress-spinner *ngIf="isLoading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
            <button 
            class="flex-shrink-0 text-sm c-button-white py-2 px-2 mr-2"
            (click)="close(dialog)">
                <span>Cancelar</span>
            </button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #dialog3 class="dialog">
    <div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
        <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
            <div class="text-lg font-medium">Reactivar usuario</div>
            <button mat-icon-button (click)="close(dialog)" [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
        <form class="flex flex-col mt-4 px-8 bg-card shadow rounded overflow-hidden">
  
            <div class="grid grid-cols-1 gap-2">
                <div class="flex flex-col gt-xs:flex-row py-6 text-center">
                    <h3 class="" for="grid-last-name">
                      Al reactivar a este usuario, le concederá acceso a la plataforma <br>
                        ¿Realmente desea reactivarlo?
                    </h3>
                </div>
            </div>
        </form>
        <fuse-alert class="mt-8 -mb-4 px-8" *ngIf="showPass" [appearance]="'outline'" [showIcon]="false"
            [type]="alert.type">
            {{alert.message}}
        </fuse-alert>
        <div class="py-5 flex flex-row-reverse  mr-5">
  
            <button mat-flat-button [color]="'primary'" (click)="activateUser()">
                <span>
                    Confirmar
                </span>
                <mat-progress-spinner *ngIf="isLoading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
            <button class="sm:mx-3" mat-stroked-button (click)="close(dialog)">
                <span>Cancelar</span>
            </button>
        </div>
    </div>
  </ng-template>
  
  <ng-template #dialog class="dialog">
    <div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
        <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-contisign text-on-primary">
            <div class="text-lg font-medium">Alta masiva de usuario</div>
            <button mat-icon-button (click)="close(dialog)" [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
        <form class="flex flex-col mt-4 px-8 bg-card shadow rounded overflow-hidden">
            <div class="grid grid-cols-1 gap-2">
                <div class="flex flex-col gt-xs:flex-row py-6 text-center">
                    <h3 class="" for="grid-last-name">
                      Carga el archivo .xlsx
                    </h3>
                    <button mat-flat-button (click)="uploadFile.click()" class="mt-5 mx-10">
                      {{ fileName ? 'Reemplazar archivo' : 'Seleccionar archivo'}}
                      <mat-icon class="icon-size-5 mx-4" matPrefix [svgIcon]="'heroicons_solid:upload'">
                      </mat-icon>
                  </button>
                  <input hidden type="file" (change)="uploadFileExcel($event)" #uploadFile>
                </div>
            </div>
        </form>
        <fuse-alert *ngIf="showAlert" [appearance]="'outline'" class="mt-10" [showIcon]="false" [type]="alert.type">
          {{alert.message}}
        </fuse-alert>
        <div class="py-5 flex flex-row-reverse  mr-5">
            <button 
              class="flex-shrink-0 text-sm c-button py-2 px-2 mr-2"
              (click)="saveFile()">
                <span>
                    Confirmar
                </span>
                <mat-progress-spinner *ngIf="isLoading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
            <button 
            class="flex-shrink-0 text-sm c-button-white py-2 px-2 mr-2"
            (click)="close(dialog)">
                <span>Cancelar</span>
            </button>
        </div>
    </div>
  </ng-template> -->