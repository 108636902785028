import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UsersService } from "../../../services/users/users.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import * as moment from "moment";
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { StudyData } from "src/app/shared/interfaces/data.interface";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Route } from "@angular/compiler/src/core";
import * as ExcelJS from 'exceljs';
@Component({
  selector: 'app-detail-history-clinic',
  templateUrl: './detail-history-clinic.component.html',
  styleUrls: ['./detail-history-clinic.component.scss']
})
export class DetailHistoryClinicComponent {

  @ViewChild('tabset') tabset: any;
  loading = true;
  downloadFile = false;
  age: number;
  arrayData: any;
  arrayDataUser: any;
  currentUserId: any;
  clickInputBoolean: boolean = false;
  currentUser: any;
  arrayFamiliares: any;
  public optionSelected: string[] = [];
  collaborador: any;
  studies: StudyData[] = [];
  catalogStudies: any[] = []; // Lista de opciones del endpoint
  works: any[] = [];
  arrayFactores: any;
  rallyData: any;
  modalConfig: NgbModalOptions = {
    ariaLabelledBy: 'modal-basic',
    windowClass: 'myCustomModalClass'
  };
  workSelected: any;
  historyReason: string = "0";
  onSee: boolean = false;
  historyData: any;
  peso: number = 0;
  estatura: number = 0;
  cercaAnswers: any;
  lejosAnswers: any;
  sentidoQuestion: any;
  visionQuestion: any;
  reflejosQuestion: any;
  extremidadesQuizes: any = [];
  otherQuizes: any = [];

  constructor(
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private _usersService: UsersService,
    private zone: NgZone,
    private _snackBar: MatSnackBar,
    private modalService: NgbModal,
  ) {

    this.currentUserId = this.route.snapshot.paramMap.get('id');

    this.loadData(-1);
    this._usersService.getCatalogFamiliar().subscribe(data => {
      this.arrayFamiliares = data;
    })

    this._usersService.getCatalogFactor().subscribe(data => {
      this.arrayFactores = data;
    })
  }

  get imc() {
    if (this.peso <= 0 || this.estatura <= 0) {
      return 0;
    }

    const imc = this.peso / (this.estatura * this.estatura);
    if (isNaN(imc)) return 0;
    return imc.toFixed(2);
  }

  generateIMC(question: any) {
    if (question.Question.includes("Peso")) {
        if (question.Answer !== "") {
            this.peso = parseFloat(question.Answer);
        }
    }

    if (question.Question.includes("Talla")) {
        if (question.Answer !== "") {
            this.estatura = parseFloat(question.Answer);
        }
    }
  }

  ngOnInit(): void {
    this.fetchEstudios();
  }

  // Método para obtener los datos del endpoint
  fetchEstudios(): void {
    this.usersService.getEstudio().subscribe((data) => {
      this.catalogStudies = data || []; // Guardar la lista obtenida
    });
  }

  openContentWork(content) {
    this.addWork();
    this.workSelected = this.works[this.works.length - 1];
    this.modalService.open(content, this.modalConfig);
  }

  setResponseFactor(event: any, qindex: any, index: any) {
    const quiz = this.workSelected;
    const value = event.target.value;
    if (!quiz.agentes.includes(value)) {
      quiz.agentes.push(value);
    } else {
      const index = quiz.agentes.indexOf(value);
      quiz.agentes.splice(index, 1);
    }
  }

  setResponseFamiliares(event: any, question: any) {
    const value = event.target.value;

    if (!question.Answerback) {
      question.Answerback = [value];
    } else {
      if (!question.Answerback.includes(value)) {
        question.Answerback.push(value);
      } else {
        const index = question.Answerback.indexOf(value);
        question.Answerback.splice(index, 1);
      }
    }
  }

  setResponse(event: any, qindex: any, index: any) {
    const quiz = this.arrayData[qindex];
    const response = event.target.value.split("-");
    const question = quiz.questions[index];
    question.Answer = response[0];
    question.AnswerId = response[1] || null;
  }

  setResponseRally(event: any, stage: any, quiz: any, question: any) {
    const quizData = this.rallyData.stages[stage].quizes[quiz];
    const value = event.target.value.split(" - ");
    quizData.questions[question].Answer = value[0];
    quizData.questions[question].AnswerId = value[1];
    quizData.questions[question].Answerback = '';
  }

  submitRally(stage: number) {

    const userResponse = [];
    const stageData = this.rallyData.stages[stage];

    if (stageData.quizes[0].questions[0].ResultId) {
      stageData.quizes.forEach((quiz: any, index2: number) => {
        quiz.questions.forEach((question: any, index: number) => {
          const response = {
            AnswerId: parseInt(question.AnswerId),
            id: question.ResultId,
            Answers: ''
          }
          response.Answers = question.Answer === 'Sí' || question.Answer === 'Anormal' ? `${question.Answer} - ${question.Answerback}` : question.Answer
          userResponse.push(response);
        });
      });

      this._usersService.updateQuizAnswers(userResponse).subscribe(data => { });

    } else {
      const quizIds = stageData.quizes.map((q: any) => { return q.id });
      stageData.quizes.forEach((quiz: any, index2: number) => {
        quiz.questions.forEach((question: any, index: number) => {
          const response = {
            QuizId: quiz.id,
            QuestionId: question.id,
            AnswerId: question.AnswerId,
            Answers: ''
          }
          response.Answers = question.Answer === 'Sí' || question.Answer === 'Anormal' ? `${question.Answer} - ${question.Answerback}` : question.Answer
          userResponse.push(response);
        });
      });

      for (let i = 0; i < quizIds.length; i++) {
        const quizesResponse = userResponse.filter((response: any) => response.QuizId === quizIds[i]);
        const body = {
          UserId: this.currentUserId,
          QuizId: quizIds[i],
          Comments: stageData.quizes[i].comments,
          answers: quizesResponse,
        }

        this._usersService.addHistoryClinic(body).subscribe(data => { });
      }
    }

    setTimeout(() => {
      this.addToast("Datos guardados correctamente", 'success-snackbar');
      this.loadData(4);
    }, 1500);
    //}
  }

  async loadData(index: number) {
    const data2 = await this.usersService.getCollaborator(this.currentUserId).toPromise();

    const historyId = this.route.snapshot.paramMap.get('historyId');

    if (historyId) {
      this.onSee = true;
      this.historyData = await this.usersService.getHistoryData(historyId).toPromise()
      this.historyReason = this.historyData.reason;
    }
    this.collaborador = data2;
    const birthdayMoment = moment(data2.dateOfBirth);
    this.age = moment().diff(birthdayMoment, 'years');

    const arrData = [1, 3, 2, 6, 19];
    if (data2.gender === 'femenino') arrData.splice(3, 0, 4);
    const observables2 = arrData.map((element: any) => {
      return this._usersService.getQuiz(element)
    });

    forkJoin(observables2).subscribe((results: any) => {
      this.arrayData = [];
      results.forEach((data: any) => {
        data.comments = '';
        data.questions = data.questions.map((question: any) => {
          if (question.Type === 'radio' || question.Type === 'other') {
            let index = question.answers.find((answer: any) => answer.Answer === 'No');
            if (index) {
              question.Answer = index.Answer;
              question.AnswerId = index.id;
              question.Answerback = data.Title.includes('Familiares') ? [] : null;
            }
            index = question.answers.find((answer: any) => answer.Answer === 'Normal');
            if (index) {
              question.Answer = index.Answer;
              question.AnswerId = index.id;
              question.Answerback = data.Title.includes('Familiares') ? [] : null;
            }
            index = question.answers.find((answer: any) => answer.Answer === 'Sin alteraciones');
            if (index) {
              question.Answer = index.Answer;
              question.AnswerId = index.id;
              question.Answerback = data.Title.includes('Familiares') ? [] : null;
            }
            index = question.answers.find((answer: any) => answer.Answer === 'Completos');
            if (index) {
              question.Answer = index.Answer;
              question.AnswerId = index.id;
              question.Answerback = data.Title.includes('Familiares') ? [] : null;
            }
          } else {
            question.AnswerId = null;
            question.Answer = null;
          }
          question.AnswerSelected = 0;
          question.AnswerCorrect = null;
          return question;
        });
        this.arrayData.push(data);
      });
    });

    this.rallyData = await this.usersService.getExplorationPhysic(1).toPromise();
    const stageData = this.rallyData.stages[0];

    stageData.quizes.sort((a, b) => a.Order - b.Order);


    stageData.quizes.forEach((q: any) => {
      q.comments = '';
      q.questions = q.questions.map((question: any) => {
        if (question.Type === 'radio' || question.Type === 'other') {
          let index = question.answers.find((answer: any) => answer.Answer === 'No');
          if (index) {
            question.Answer = index.Answer;
            question.AnswerId = index.id;
            question.Answerback = null;
          }
          index = question.answers.find((answer: any) => answer.Answer === 'Normal');
          if (index) {
            question.Answer = index.Answer;
            question.AnswerId = index.id;
            question.Answerback = null;
          }
          index = question.answers.find((answer: any) => answer.Answer === 'Sin alteraciones');
          if (index) {
            question.Answer = index.Answer;
            question.AnswerId = index.id;
            question.Answerback = null;
          }
          index = question.answers.find((answer: any) => answer.Answer === 'Completos');
          if (index) {
            question.Answer = index.Answer;
            question.AnswerId = index.id;
            question.Answerback = null;
          }
        } else {
          question.AnswerId = null;
          question.Answer = null;
        }
        question.AnswerSelected = 0;
        question.AnswerCorrect = null;
        return question;
      });
    });

    let data = await this.usersService.getQuizsByUser(this.currentUserId).toPromise();
    if (historyId) {
      data = await this.usersService.getQuizsByHistory(historyId).toPromise();
    }
    this.arrayDataUser = data;
    const observables = this.arrayDataUser.map((element: any) => {
      return this.usersService.getAnswersByQuiz(element?.id);
    });

    forkJoin(observables).subscribe((results: any) => {
      results.forEach((data: any) => {
        const index = this.arrayData.findIndex((ad: any) => ad.id === data.quiz.id);
    
        if (index !== -1) {
          this.arrayData[index].comments = data.comments;
          this.arrayData[index].answers = data.answers;
    
          for (const answer of data.answers) {
            const questions = this.arrayData[index].questions;
            const question = questions.find((q: any) => q.id === answer.QuestionId);
    
            question.AnswerId = answer.AnswerId;
            question.ResponseId = answer.ResponseId;
    
            if (answer.Answer) {
              const response = answer.Answers.split(" - ");
    
              question.Answer = response[0].trim();
              question.AnswerSelected = response[1] ? response[1].trim() : null;
              question.AnswerCorrect = true;
    
              if (!this.arrayData[index].Title.includes('Familiares')) {
                question.Answerback = response[1] || '';
              } else {
                question.Answerback = response[1] ? response[1].split(",") : null;
              }
    
              if (question.Question === 'Peso') {
                this.peso = question.Answer;
              }
    
              if (question.Question === 'Estatura') {
                this.estatura = question.Answer;
              }
            }
          }
    
          if (data.quiz.Title.includes('Antropometríco') || data.quiz.Title.includes('Laboratorio')) {
            this.otherQuizes.push(this.arrayData[index]);
          }
    
        } else {
          const newIndex = stageData.quizes.findIndex((ad: any) => ad.id === data.quiz.id);
          const quiz = stageData.quizes[newIndex];
    
          if (quiz.Description.includes('Extremidades')) {
            this.extremidadesQuizes.push(quiz);
          }
    
          if (quiz.Description.includes("Agudeza Visual")) {
            const cerca = quiz.questions.filter((q) => q.Question.includes('derecho'));
            const lejos = quiz.questions.filter((q) => q.Question.includes('izquierdo'));
            const correccion = quiz.questions.filter((q) => q.Question.includes('anteojos'));
            this.sentidoQuestion = quiz.questions.filter((q) => q.Question.includes('Sentido'))[0];
            this.visionQuestion = quiz.questions.filter((q) => q.Question.includes('Visión'))[0];
            this.reflejosQuestion = quiz.questions.filter((q) => q.Question.includes('Reflejos'))[0];
    
            cerca.push(correccion[0]);
            lejos.push(correccion[0]);
            this.cercaAnswers = cerca;
            this.lejosAnswers = lejos;
          }
    
          for (let i = 0; i < data.answers.length; i++) {
            const question = data.answers[i];
            const questionIdx = quiz.questions.findIndex((q: any) => q.id == question.QuestionId);
          
            // Verificar si se encontró el índice de la pregunta
            if (questionIdx === -1) {
              console.warn(`Pregunta con ID ${question.QuestionId} no encontrada en quiz.questions`);
              continue;
            }
          
            quiz.comments = data.comments;
            quiz.questions[questionIdx].Answers = question.Answers ? question.Answers.split(" - ")[0] : '';
            quiz.questions[questionIdx].Answer = question.Answers ? question.Answers.split(" - ")[0] : '';
            quiz.questions[questionIdx].ResultId = question.ResponseId;
            quiz.questions[questionIdx].Answerback = question.Answers ? question.Answers.split(" - ")[1] : '';
          }          
        }
      });
    });
    
    this.tabset.select('tab' + (index === -1 ? 0 : (index + 1)));
    window.scrollTo(0, 0);
    
    let dataFiles;
    if (historyId) {
      dataFiles = this.historyData.HistoryFiles;
    } else {
      dataFiles = await this.usersService.getCollaboratorFiles(this.currentUserId).toPromise();
    }
    
    if (dataFiles.length > 0) {
      this.studies = dataFiles.map((study: StudyData) => {
        study.saved = true;
        return study;
      });
    } else {
      this.studies = [];
      this.studies.push({ name: 'Certificado de Aptitud Médico Laboral', date: moment().format("DD/MM/Y"), file: '', saved: false, fileName: '', collaborator_id: this.currentUserId });
    }
    
    let dataExperiences;
    if (historyId) {
      dataExperiences = this.historyData.HistoryExperiences;
      //console.log("Experiencias desde historyData:", dataExperiences);
    } else {
      dataExperiences = await this.usersService.getCollaboratorExperience(this.currentUserId).toPromise();
      //console.log("Experiencias obtenidas del servicio:", dataExperiences);
    }
    
    this.works = dataExperiences.map((work: any) => {
      //console.log("Procesando experiencia laboral:", work);
      if (work.agentes) { // Verificamos que work.agentes no sea null
        work.agentes = work.agentes.split("-");
      }
      work.saved = true;
      return work;
    });
    
    this.loading = false;
    //console.log("Carga finalizada, estado de loading:", this.loading);    

  }

  nextStep(index) {
    if (index === 4) {
      const body = {
        accidentes: this.collaborador.accidentes,
        enfermedad: this.collaborador.enfermedad,
        sustancia: this.collaborador.sustancia,
        padecimiento: this.collaborador.padecimiento,
        actividad: this.collaborador.actividad
      }
      this._usersService.updateCollaborator(this.currentUserId, body).subscribe(data => {
        this.tabset.select('tab' + index);
        window.scrollTo(0, 0);
      });
    } else {
      this.tabset.select('tab' + index);
      window.scrollTo(0, 0);
    }
  }

  saveHistory() {
    if (this.historyReason === '0') {
        this.addToast('Debes especificar el tipo de examen', 'error-snackbar');
        return;
    }

    /* if (this.studies.some((study: any) => study.file === '')) {
        this.addToast('Hay archivos pendientes de cargar', 'error-snackbar');
        return;
    } */

    // Recuperar la lista de trabajos desde localStorage
    const storedWorks = JSON.parse(localStorage.getItem('works') || '[]');

    // Filtrar trabajos guardados
    const experienceIds = storedWorks
        .filter((item: any) => item.saved && item.id) // Solo los guardados y con ID
        .map((item: any) => item.id);

    if (experienceIds.length === 0) {
        this.addToast('No hay trabajos guardados para enviar.', 'error-snackbar');
        return;
    }

    const filesIds = this.studies?.length ? this.studies.map((item: any) => item.id) : [];
    const quizesIds = this.arrayDataUser.map((item: any) => item.id);
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const body = {
        userId: user.id,
        reason: this.historyReason,
        elaborationDate: moment().format("Y-MM-DD"),
        cliniccollaborator_id: this.currentUserId,
        works: experienceIds,
        studies: filesIds,
        quizes: quizesIds
    };

    this.usersService.saveCollaboratorHistory(body).subscribe((data) => {
        this.addToast(data.Message, 'success-snackbar');

        // Limpiar los works de localStorage después de guardar
        localStorage.removeItem('works');

        this.router.navigate(['/colaboradores/history-clinic', this.currentUserId]);
    });
  }

  deleteStudy(index: number) {
    const study = this.studies[index];
    console.log('Estudio a eliminar:', study);
  
    if (!study.id) {
      this.addToast('No se puede eliminar un estudio que no está guardado.', 'error-snackbar');
      return;
    }
  
    /* const body = { 
      id: study.id,
      clinicexperience_id: study.id
    }; */
  
    const fileId = study.id;
    // Llama al servicio para eliminar el archivo en el servidor
    this._usersService.deleteCollaboratorFile(fileId).subscribe({
      next: () => {
        this.studies.splice(index, 1); // Elimina el estudio de la lista local
        this.addToast('Estudio eliminado correctamente.', 'success-snackbar');
      },
      error: (err) => {
        console.error('Error al eliminar el archivo:', err);
        this.addToast('Ocurrió un error al intentar eliminar el estudio.', 'error-snackbar');
      },
    });
  }  
  
  toggleLabel(id: string, q, i) {
    const name = `${id}${q}-${i}`;
    const select = document.getElementById(name) as any;
    const label = select.nextElementSibling;
    label.style.top = select.selectedIndex === 0 ? '50%' : '-11px';
    label.style.fontSize = select.selectedIndex === 0 ? '14px' : '12px';
    label.style.color = select.selectedIndex === 0 ? '#888' : '#888';
  }

  async onFileSelected(event: any, index: number) {
    const file: File = event.target.files[0];
    const maxSize = 1024 * 1024 * 10;
    if (file.size > maxSize) {
      this.addToast('Tus anexos no pueden pesar más de 10 MB', 'error-snackbar');
      return;
    }
    if (file) {
      const formData = new FormData();
      const filename = file.name.split(".");
      formData.append('file', file, filename.join(new Date().getTime() + '.'));
      formData.append('type', 'annexed');

      const resolve = await this._usersService.uploadFile(formData).toPromise();
      this.studies[index].file = resolve.ImageUrl;
      this.studies[index].fileName = resolve.ImageUrl.split('/').pop();
    }
  }

  async uploadImageFile(event: any) {
    const file: File = event.target.files[0];
    const maxSize = 1024 * 1024 * 10;
    if (file.size > maxSize) {
      this.addToast('Tus anexos no pueden pesar más de 10 MB', 'error-snackbar');
      return;
    }
    if (file) {
      const formData = new FormData();
      const filename = file.name.split(".");
      formData.append('file', file, filename.join(new Date().getTime() + '.'));
      formData.append('type', 'img');

      const resolve = await this._usersService.uploadFile(formData).toPromise();
      this.collaborador.imageFileUrl = resolve.ImageUrl;
      const body = {
        imageFileUrl: resolve.ImageUrl
      }
      this._usersService.editCollaborator(this.currentUserId, body).subscribe((data: any) => {
        this.addToast('Imagen cargada exitosamente', 'success-snackbar');
      })
    }
  }

  changeDate(event): void {
    const select = document.getElementById(event) as any;
    select.type = 'date';
    const label = select.nextElementSibling;
    label.style.top = '-21px';
    label.style.fontSize = '12px';
    label.style.color = '#888';
  }

  addStudy() {
    this.studies.push({ name: '', date: '', file: '', saved: false, fileName: '', collaborator_id: this.currentUserId });
  }

  addWork() {
    this.works.push({
      name: '',
      position: '',
      currentActivity: '',
      seniority: '',
      yearSeniority: '',
      monthSeniority: '',
      epp: '',
      enfermedad: '',
      saved: false,
      comments: '',
      padecimieto: '',
      collaborator_id: this.currentUserId,
    });
  }

  saveStudy(index: number) {
    const study = this.studies[index];
    if (study.name === '' || study.date === '') {
      this.addToast('Debes llenar todos los campos', 'error-snackbar');
      return;
    }

    if (study.file === '') {
      this.addToast('Debes cargar el archivo para continuar', 'error-snackbar');
      return;
    }

    this._usersService.saveCollaboratorFile(this.currentUserId, study).subscribe((data) => {
      study.saved = true;
      study.id = data.id;
      this.addToast('Archivo guardado correctamente', 'success-snackbar');
    });
  }

  saveStudies() {
    const observables2 = this.studies.map((element: any) => {
      return this._usersService.saveCollaboratorFile(this.currentUserId, element)
    });
    forkJoin(observables2).subscribe((results: any) => {
      this.addToast('Expediente actualizado correctamente', 'success-snackbar');
    })
  }

  saveWork(index: number) {
    const work = this.workSelected;
    if (work.name === '' || work.position === '' || work.positionDescription === '') {
        this.addToast('Debes llenar todos los campos', 'error-snackbar');
        return;
    }
    if (work.yearSeniority === '' && work.monthSeniority === '') {
        this.addToast('Debes especificar la antigüedad correctamente', 'error-snackbar');
        return;
    }
    work.seniority = `${work.yearSeniority || 0} años ${work.monthSeniority || 0} meses`;

    this._usersService.saveCollaboratorExperience(this.currentUserId, work).subscribe((data) => {
        work.saved = true;
        work.id = data.id;

        // Actualizar la lista en `this.works`
        this.works[index] = work;

        // Guardar la lista en localStorage
        localStorage.setItem('works', JSON.stringify(this.works));

        this.modalService.dismissAll();
        this.addToast('Antecedente guardado correctamente', 'success-snackbar');
    });
  }

  clickInputToggle() {
    this.clickInputBoolean = !this.clickInputBoolean
  }

  setCorrect(event: any, qindex: any, index: any) {
    const quiz = this.arrayData[qindex];
    quiz.questions[index].AnswerCorrect = event.target.value !== '0';
  }

  async generateExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('HistoriaClinica');
  
    // Recorrer `arrayData`
    this.arrayData.forEach((data: any) => {
      const row = worksheet.addRow([data.Title]);
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ed7d31' },
        bgColor: { argb: 'ed7d31' }
      };
      row.font = { name: 'Arial', family: 4, size: 14, bold: true };
  
      data.questions.forEach((question: any) => {
        // Manejar respuestas correctamente
        const answer = question.Type === 'radio'
          ? question.Answer && typeof question.Answer === 'string' && 
            (question.Answer.includes("Sí") || question.Answer.includes("Anormal"))
            ? `${question.Answer} - ${question.Answerback || ''}`
            : question.Answer || 'N/A'
          : question.Answerback || 'N/A';
  
        worksheet.addRow([question.Question, answer]);
      });
  
      worksheet.addRow(['Comentarios', data.comments || 'N/A']);
    });
  
    // Recorrer `rallyData.stages[0].quizes`
    this.rallyData.stages[0].quizes.forEach((data: any) => {
      const row = worksheet.addRow([data.Title]);
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ed7d31' },
        bgColor: { argb: 'ed7d31' }
      };
      row.font = { name: 'Arial', family: 4, size: 14, bold: true };
  
      data.questions.forEach((question: any) => {
        const answer = question.Type === 'radio'
          ? question.Answer && typeof question.Answer === 'string' &&
            (question.Answer.includes("Sí") || question.Answer.includes("Anormal"))
            ? `${question.Answer} - ${question.Answerback || ''}`
            : question.Answer || 'N/A'
          : question.Answerback || 'N/A';
  
        worksheet.addRow([question.Question, answer]);
      });
  
      worksheet.addRow(['Comentarios', data.comments || 'N/A']);
    });
  
    // Antecedentes laborales
    let row = worksheet.addRow(['Antecedentes laborales']);
    row.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ed7d31' },
      bgColor: { argb: 'ed7d31' }
    };
    row.font = { name: 'Arial', family: 4, size: 14, bold: true };
  
    this.works.forEach((work: any) => {
      worksheet.addRow([
        work.name,
        work.position,
        work.positionDescription,
        work.agentes ? work.agentes.join(", ") : 'N/A',
        work.seniority || 'N/A',
        work.accident || 'N/A',
        work.accidentDescription || 'N/A'
      ]);
    });
  
    // Expediente
    row = worksheet.addRow(['Expediente']);
    row.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ed7d31' },
      bgColor: { argb: 'ed7d31' }
    };
    row.font = { name: 'Arial', family: 4, size: 14, bold: true };
  
    this.studies.forEach((work: any) => {
      worksheet.addRow([
        work.name || 'N/A',
        work.date || 'N/A',
        work.file || 'N/A'
      ]);
    });
  
    // Ajustar tamaño de columnas
    worksheet.columns.forEach((column) => {
      column.width = 40;
    });
  
    // Generar archivo y descargar
    const blob = await workbook.xlsx.writeBuffer();
    const blobURL = URL.createObjectURL(
      new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    );
  
    const link = document.createElement('a');
    link.href = blobURL;
  
    const date = new Date();
    link.download = `${this.collaborador.firstName}-${date.getMilliseconds()}.xlsx`;
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  

  submitFormFamilia(q: any) {
    //console.log("submitFormFamilia llamado con índice:", q);

    const quiz = this.arrayData[q];
    //console.log("Datos del quiz:", quiz);

    // Cálculo del IMC antes de procesar las respuestas
    const pesoQuestion = quiz.questions.find((question: any) => question.Question.includes('Peso'));
    const tallaQuestion = quiz.questions.find((question: any) => question.Question.includes('Talla'));
    const imcQuestion = quiz.questions.find((question: any) => question.Question.includes('IMC'));

    if (pesoQuestion && tallaQuestion && imcQuestion) {
        const peso = parseFloat(pesoQuestion.Answer || 0);
        const estatura = parseFloat(tallaQuestion.Answer || 0);
        if (peso > 0 && estatura > 0) {
            const imc = (peso / (estatura * estatura)).toFixed(2);
            imcQuestion.Answer = imc; // Actualiza el campo Answer de la pregunta IMC
            //console.log("IMC calculado y asignado:", imc);
        }
    }

    const answers = quiz.questions.map((q: any) => {
        const t = {
            QuestionId: q.id,
            AnswerId: q.AnswerId ? parseInt(q.AnswerId, 10) : null,
            AnswerCorrect: q.AnswerCorrect || null,
            ResponseId: q.ResponseId || null,
            Answers: ''
        };

        //console.log("Procesando pregunta:", q);

        if (q.Answer) {
            if (q.Type === 'radio' || q.Type === 'other' || q.Type === 'more') {
                t.Answers = q.Answerback ? `${q.Answer} - ${q.Answerback}` : `${q.Answer}`;
            } else if (['text', 'number', 'date', 'select'].includes(q.Type)) {
                t.Answers = q.Answer;
            }
        }
        return t;
    });

    //console.log("Respuestas procesadas:", answers);

    const body = {
        UserId: this.currentUserId,
        QuizId: quiz.id,
        Comments: quiz.comments,
        answers
    };

    //console.log("Cuerpo de la petición preparado:", body);

    if (!quiz.answers) {
        //console.log("Enviando nueva historia clínica...");
        this._usersService.addHistoryClinic(body).subscribe({
            next: (data) => {
                //console.log("Respuesta del servidor al guardar datos:", data);
                this.addToast("Datos guardados correctamente", 'success-snackbar');
                this.loadData(q < 3 ? q : q + 2);
            },
            error: (err) => {
                console.error("Error al guardar los datos", err);
                this.addToast("Error al guardar los datos", 'error-snackbar');
            }
        });
    } else {
        const answers2 = answers.map((ans) => ({
            AnswerId: parseInt(ans.AnswerId, 10),
            id: ans.ResponseId,
            Answers: ans.Answers
        }));

        //console.log("Enviando actualización de respuestas:", answers2);

        this._usersService.updateQuizAnswers(answers2).subscribe({
            next: (data) => {
                //console.log("Respuesta del servidor al actualizar datos:", data);
                this.addToast("Datos actualizados correctamente", 'success-snackbar');
                this.loadData(q < 3 ? q : q + 2);
            },
            error: (err) => {
                console.error("Error al actualizar las respuestas", err);
                this.addToast("Error al actualizar las respuestas", 'error-snackbar');
            }
        });
    }
  }

  generatePdf() {
    this.downloadFile = true;
    const contenidoPDF = document.getElementById('pdfId').innerHTML;
    const body = {
      html: contenidoPDF,
      historyId: this.historyData.id,
      collaboratorId: this.currentUserId
    };

    this._usersService.generatePdf(body).subscribe((data: any) => {
        this.downloadFile = false;
        window.open(URL.createObjectURL(data), '_blank');
    })
  }

  addToast(message: any, className: string) {
    this.zone.run(() => {
      this._snackBar.open(message, null, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: [className],
      });
    });
  }

}
