<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-body d-flex custom-flex-admin-list justify-content-between align-items-end w-100" style="padding-left: 30px !important; padding-right: 30px !important;">
      <div class="row d-flex flex-column col-lg-10 col-md-10 col-sm-12 pr-0 mx-0 custom-margin-enterprise-input-mobile" style="padding-left: 0px;">
        <!--  <label>Filtrar</label> -->
        <div class="input-group" style="width: 100%">
          <input type="text" id="filterText" [(ngModel)]="adminFilter"
            placeholder="Buscar por nombre, correo o teléfono" class="input-font-size form-control">
          <div class="input-group-append">
            <button *ngIf="!deleteFilters" value="Buscar" (click)="filter()"
              class="button-font-size btn btn-primary">Buscar</button>
            <button *ngIf="deleteFilters" value="Borrar" (click)="getUsers()"
              class="button-font-size btn btn-primary">Borrar filtros</button>
          </div>
        </div>
      </div>
      <div class="row action pr-0 mx-0 col-lg-2 col-md-2 custom-margin-enterprise-mobile">
        <a class="custom-admin-button-width button-font-size btn btn-red btn-sm px-2"
          [routerLink]="['create-user']" style="width: 100%">
          <i class="ti-plus"></i>
          <span><i class="fa fa-plus"></i> Administrador</span>
        </a>
      </div>
    </div>
  </div>

  <div class="card custom-card-margin">
    <div class="card-body tabla-card-margin">
      <div id="batchDelete" class="">
        <div class="table-responsive">
          <table mat-table (matSortChange)="announceSortChange($event)" #table [dataSource]="dataSource" matSort
            class="table table-lg table-hover row-border hover">
            <ng-container matColumnDef="firstName">
              <th *matHeaderCellDef mat-sort-header>Nombre(s)</th>
              <td *matCellDef="let row">{{row.firstName}} {{row.lastName}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th *matHeaderCellDef mat-sort-header>Correo electrónico</th>
              <td *matCellDef="let row">{{row.email}}</td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
              <th *matHeaderCellDef mat-sort-header>Numero telefónico</th>
              <td *matCellDef="let row">{{row.phoneNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="active">
              <th *matHeaderCellDef mat-sort-header>Estatus</th>
              <td *matCellDef="let row">
                <button class="btn" [ngClass]="{'btn-primary': row.active, 'btn-info': !row.active}">
                  {{ row.active ? 'Activo' : 'Inactivo' }}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th *matHeaderCellDef mat-sort-header>Acciones</th>
              <td *matCellDef="let row" style="font-size: 16px;">
                <span>
                  <a [routerLink]="['show', row.id]" title="Ver administrador">
                    <i class="fa fa-eye"></i>
                  </a>
                </span>
                <span>
                  <a [routerLink]="['edit', row.id]" title="Editar administrador">
                    <i class="fa fa-edit"></i>
                  </a>
                </span>
                <span (click)="setModalContent(content, row.id)" *ngIf="row.active" title="Borrar administrador">
                  <i class="fa fa-trash"></i>
                </span>

                <span (click)="setModalContent(contentactive, row.id)" *ngIf="!row.active"
                  title="Activar administrador">
                  <i class="fa fa-check"></i>
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <!--  <thead>
                      <tr>
                      <th>Nombre(s)</th>
                      <th>Correo electrónico</th>
                      <th>Teléfono</th>
                      <th>Status</th>
                      <th>Acciones</th>
                      </tr>
                  </thead> -->
            <!--    <tbody>
                      <tr *ngFor="let user of user_list">
                      <td>{{user.firstName}} {{user.lastName}}</td>
                      <td>{{user.email}}</td>
                      <td>{{user.phoneNumber}}</td>
                      <td>{{user.active ? 'activo': 'inactivo'}}</td>
                      <td class="actions-cell">
                          <span>
                          <a [routerLink]="['show', user.id]"
                              title="Ver administrador">
                              <i class="fa fa-eye"></i>
                          </a>
                          </span>
                          <span>
                            <a [routerLink]="['edit', user.id]"
                                title="Editar administrador">
                                <i class="fa fa-edit"></i>
                            </a>
                          </span>
                          <span (click)="setModalContent(content, user.id)"*ngIf="user.active"
                          title="Borrar administrador">
                          <i class="fa fa-trash"></i>
                          </span>

                          <span (click)="setModalContent(contentactive, user.id)" *ngIf="!user.active"
                            title="Activar administrador">
                            <i class="fa fa-check"></i>
                            </span>

                      </td>
                      </tr>
                  </tbody> -->
          </table>
          <ngb-pagination (pageChange)="loadPage($event)" [(collectionSize)]="count" [(page)]="page" [pageSize]="limit"
            [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100" id="modal-title">Eliminar Administrador</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Se eliminirán todos los datos del administrador.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Administrador borrado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
      <button type="button" class="btn btn-inverse btn-danger" (click)="deleteUser()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>

<ng-template #contentactive let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100" id="modal-title">Activar Administrador</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">Activara a este administrador dentro de la plataforma</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Administrador activado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
      <button type="button" class="btn btn-inverse btn-success" (click)="activeUser()">Activar</button>
    </div>
  </ng-container>
</ng-template>