<div class="container-fluid mt-0 container-enterprise">
  <div class="d-flex justify-content-between px-3 pb-3 w-100">
    <div>
      <h4 class="">Colaboradores</h4>
    </div>
    <div>
      <h4 class="custom-list-collaborators-margin">Total de colaboradores : {{collaboratorTotal}}</h4>
    </div>
  </div>
  <div class="card custom-card-margin">
    <div class="card-body d-flex justify-content-between align-items-end w-100">
      <div class="row d-flex flex-column col-lg-12 col-sm-12 mx-1" style="padding-left: 0px; padding-left: 0px;">
        <!--  <label>Filtrar</label> -->
        <div class="input-group w-100">
          <input type="text" id="filterText" [(ngModel)]="colaboratorsFilter"
            placeholder="Buscar por nombre de empresa, representante" class="input-font-size form-control">
          <div class="input-group-append">
            <button *ngIf="!deleteFilters && userRoles == 'admin'" value="Buscar" (click)="filter(colaboratorsFilter)"
              class="button-font-size btn btn-primary">Buscar</button>
            <button *ngIf="!deleteFilters && userRoles == 'business'" value="Buscar"
              (click)="filterBusiness(colaboratorsFilter)" class="button-font-size btn btn-primary">Buscar</button>
            <button *ngIf="deleteFilters" value="Borrar" (click)="getCollaborators()"
              class="button-font-size btn btn-primary">Borrar filtros</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card custom-card-margin">
    <div class="card-body tabla-card-margin">
      <div id="batchDelete" class="body overflow-auto">
        <div class="table-responsive">
          <table *ngIf='collaboratorsList.length > 0' mat-table (matSortChange)="announceSortChange($event)" #table
            [dataSource]="dataSource" matSort class="table table-responsive w-100 table-hover row-border hover">

            <ng-container matColumnDef="businessname" *ngIf="this.userRoles == 'admin'">
              <th *matHeaderCellDef mat-sort-header>Nombre de empresa</th>
              <td *matCellDef="let row">{{ row.Business?.businessname}}</td>
            </ng-container>
            <ng-container class="custom-font-size-th" matColumnDef="firstName">
              <th *matHeaderCellDef mat-sort-header>Nombre(s)</th>
              <td *matCellDef="let row">{{row.firstName}}</td>
            </ng-container>
            <ng-container class="custom-font-size-th" matColumnDef="lastName">
              <th *matHeaderCellDef mat-sort-header>Apellido(s)</th>
              <td *matCellDef="let row">{{row.lastName}}</td>
            </ng-container>
            <ng-container class="custom-font-size-th custom-widt-fecha" matColumnDef="birthday">
              <th *matHeaderCellDef mat-sort-header>Fecha de nacimiento</th>
              <td *matCellDef="let row">{{row.dateOfBirth | date: 'dd/MM/YYYY'}}</td>
            </ng-container>
            <ng-container class="custom-font-size-th" matColumnDef="Job">
              <th *matHeaderCellDef mat-sort-header>Puesto</th>
              <td *matCellDef="let row">{{row.position || 'Sin definir'}}</td>
            </ng-container>
            <ng-container class="custom-font-size-th" matColumnDef="active">
              <th *matHeaderCellDef mat-sort-header>Teléfono</th>
              <td *matCellDef="let row">
                {{ row.phone }}
              </td>
            </ng-container>
            <ng-container class="custom-font-size-th" matColumnDef="actions">
              <th *matHeaderCellDef mat-sort-header>Acciones</th>
              <td *matCellDef="let row" style="font-size: 16px;">
                <!--   <span>
                            <a 
                            (click)="sendPhone(collaborator.phoneNumber)"
                            [routerLink]="['/colaboradores/detalle', row.id]"
                                title="Ver colaborador">
                                <i class="fa fa-eye"></i>
                            </a>
                            </span> -->
                <span>
                  <a [routerLink]="['/colaboradores/editar', row.id]" (click)="sendPhone(row.phoneNumber)"
                    title="Editar colaborador">
                    <i class="fa fa-edit"></i>
                  </a>
                </span>
                <span>
                  <a [routerLink]="['/colaboradores/history-clinic', row.id]" (click)="obtenerId(row.id)"
                    title="Ver historial clinico">
                    <i class="fa fa-clipboard"></i>
                  </a>
                </span>
                <span title="Eliminar colaborador" (click)="setModalContent(content, row.id)" *ngIf="row.active">
                  <i class="fa fa-trash"></i>
                </span>
                <!-- <span>
                              <a [routerLink]="['/colaboradores/historyClinic']" (click)="obtenerId(row.id)"
                                  title="Agregar Historial Clinico">
                                  <i class="fa fa-plus"></i>
                              </a>
                            </span> -->
                <!--  <span
                            title="Eliminar colaborador" (click)="setModalContent(content, row.id)" *ngIf="row.active">
                            <i class="fa fa-trash"></i>
                            </span>
                            <span
                            title="Activar colaborador" (click)="setModalContent(contentactive, row.id)" *ngIf="!row.active">
                            <i class="fa fa-check"></i>
                            </span> -->
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <!-- <thead> 
                        <tr>
                        <th *ngIf="this.userRoles == 'admin'" class="custom-font-size-th custom-width-th-empresa">Nombre de empresa</th>
                        <th class="custom-font-size-th">Nombre(s)</th>
                        <th class="custom-font-size-th">Apellido(s)</th>
                        <th class="custom-font-size-th custom-width-th-empresa">Fecha de nacimiento</th>
                        <th class="custom-font-size-th">Puesto</th>
                        <th class="custom-font-size-th">Correo electrónico</th>
                        <th class="custom-font-size-th">Teléfono</th>
                        <th class="custom-font-size-th">Status</th>
                        <th class="custom-font-size-th custom-width-th-acciones">Acciones</th>
                        </tr>
                    </thead>
                      <tbody *ngFor="let collaborator of collaboratorsList">
                        <tr>
                        <td *ngIf="this.userRoles == 'admin'"  class="custom-font-size-td custom-width-td">{{collaborator.Business?.businessname}}</td>
                        <td class="custom-font-size-td custom-width-td">{{collaborator.firstName}}</td>
                        <td class="custom-font-size-td custom-width-td">{{collaborator.lastName}}</td>
                        <td class="custom-font-size-td custom-width-td">{{collaborator.birthday}}</td>
                        <td class="custom-font-size-td custom-width-td">{{collaborator.Job}}</td>
                        <td class="custom-font-size-td custom-width-td">{{collaborator.email}}</td>
                        <td class="custom-font-size-td custom-width-td">{{collaborator.phoneNumber}}</td>
                        <td class="custom-font-size-td custom-width-td">{{collaborator.active ? 'Activo': 'Inactivo'}}</td>
                        <td class="actions-cell custom-width-td-action">
                            <span>
                            <a 
                            (click)="sendPhone(collaborator.phoneNumber)"
                            [routerLink]="['/colaboradores/detalle', collaborator.id]"
                                title="Ver colaborador">
                                <i class="fa fa-eye"></i>
                            </a>
                            </span>
                            <span>
                              <a [routerLink]="['/colaboradores/editar', collaborator.id]"
                              (click)="sendPhone(collaborator.phoneNumber)"
                                  title="Editar colaborador">
                                  <i class="fa fa-edit"></i>
                              </a>
                            </span>
                            <span>
                              <a [routerLink]="['/colaboradores/detail-historyClinic', collaborator.id]" (click)="obtenerId(collaborator.id)"
                                  title="Ver historial clinico">
                                  <i class="fa fa-clipboard"></i>
                              </a>
                            </span>
                          <span>
                              <a [routerLink]="['/colaboradores/historyClinic']" (click)="obtenerId(collaborator.id)"
                                  title="Agregar Historial Clinico">
                                  <i class="fa fa-plus"></i>
                              </a>
                            </span>
                            <span
                            title="Activar colaborador" (click)="setModalContent(contentactive, collaborator.id)" *ngIf="!collaborator.active">
                            <i class="fa fa-check"></i>
                            </span>
                        </td>
                        </tr>
                    </tbody> -->
          </table>
          <h4 class="py-3" *ngIf="collaboratorTotal == 0">
            La empresa aún no cuenta con colaboradores registrados
          </h4>
          <ngb-pagination (pageChange)="loadPage($event)" [(collectionSize)]="collaboratorTotal" [(page)]="page"
            [pageSize]="limit" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
          <div class="d-flex justify-content-center w-100">
            <ngb-toast *ngIf="submittedError" [class]="'ngb-toast bg-error'">
              {{messageError}}
            </ngb-toast>
            <ngb-toast *ngIf="submittedSuccess" [class]="'ngb-toast bg-success'">
              {{messageSuccess}}
            </ngb-toast>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100" id="modal-title">Confirmar baja</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">¿Realmente deseas dar de baja a este colaborador?</p>
      <p class="no-mrg">Esta acción no es posible revertir.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Empresa borrada correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
      <button type="button" class="btn btn-inverse btn-danger" (click)="deleteUser()">Sí, Confirmar</button>
    </div>
  </ng-container>
</ng-template>

<ng-template #contentactive let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center width-100" id="modal-title">Activar colaborador</h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!deleteIsSuccess">
      <p class="no-mrg">¿Confirma la activación del colaborador {{deleteCollaborators}}?</p>
      <p class="no-mrg">Al dar de activar al colaborador, podrá
        acceder al sistema y cargar información de
        colaboradores.</p>
    </ng-container>

    <ng-container *ngIf="deleteIsSuccess">
      <p>Colaborador activado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!deleteIsSuccess">
    <div class="modal-footer">
      <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
      <button type="button" class="btn btn-inverse btn-success" (click)="activeUser()">Sí, Confirmar</button>
    </div>
  </ng-container>
</ng-template>